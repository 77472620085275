import { 
    GET_LOAN_DASHBOARD_DATA, 
    LOGOUT 
} from "../action";

const initialState = {};

const loanDashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_LOAN_DASHBOARD_DATA:
            return {
                ...state,
                ...action.payload,
            };
        case LOGOUT:
            return initialState;
        default:
            return state;
    }
};


export {
    loanDashboardReducer,
};