import { 
    GET_CHECKIN_TRENDS, 
    LOGOUT 
} from "./action";
const initialState = {};
const checkinTrendsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_CHECKIN_TRENDS:
            return {
                ...state,
                ...action.payload,
            };
        case LOGOUT:
            return initialState;
        default:
            return state;
    }
};

export {  
    checkinTrendsReducer
};