import { SET_SHIFTS, ADD_SHIFTS, UPDATE_SHIFTS, DELETE_SHIFTS, LOGOUT } from '../redux/action';

const initialState = {
    data: []
};

const GetShifts = (state = initialState, action) => {
    switch (action.type) {
        case SET_SHIFTS:
            return {
                ...state,
                data: action.payload.data || []
            };
        case ADD_SHIFTS:
            return {
                ...state,
                data: [...state.data, action.payload]
            };
        case UPDATE_SHIFTS:
            return {
                ...state,
                data: state.data.map(shift =>
                    shift._id === action.payload._id ? action.payload : shift
                )
            };
        case DELETE_SHIFTS:
            return {
                ...state,
                data: state.data.filter(shift => shift._id !== action.payload)
            };
        case LOGOUT:
            return initialState;
        default:
            return state;
    }
};

export default GetShifts;
