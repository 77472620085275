// store.js
import { createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // LocalStorage as the default storage
import rootReducer from './rootReducer';
import { clearPersistedData } from './action';

// Persist configuration
const persistConfig = {
  key: 'root',       // Key for storage
  storage,           // Default storage (localStorage)
  whitelist: ['auth'], // Specify which reducers should be persisted (e.g., 'auth', 'user')
};

// Create a persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create store with persisted reducer
const store = createStore(persistedReducer);
   
// Persistor to handle persisting the store
const persistor = persistStore(store);

// Optional: Clear persisted data if user data is missing or empty
persistor.subscribe(() => {
  const state = store.getState();
  if (!state.auth || Object.keys(state.auth).length === 0) {
    store.dispatch(clearPersistedData());    
  }
});

export { store, persistor };
    