// rootReducer.js
import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import selectedItemsReducer from './selectedItemsReducer';
import allowancesReducer from './allowancesReducer';
import contributionReducer from './contributionReducer';
import deductioReducer from './deductionreducer';
import taxableReducer from './taxableReducer';
import nontaxableReducer from './nontaxablereducer';
import dependentsReducer from './dependents';
import addDocsReducer from './AddDocs';
import addImageReducer from './AddImage'; 
import addFileReducer from './AddFile';
import organizationReducer from './organizationReducer';
import AddEmployee from './AddEmployee';
import departmentReducer from './department';
import designationReducer from './designation';
import GetLeave from './GetLeave';
import GetAttendance from './GetAttendance';
import GetPayroll from './GetPayroll';
import GetTransaction from './GetTransaction';
import GetEmpProfile from './GetEmployeeProfile';

import GetManagers from './GetManagers';
import profileReducer from './profileReducer';
import authReducer from './authReducer';
import AddDriver from './AddDriver';
import GetShifts from './GetShift';          


//version 2.2 
import {GetBirthday,
  organizationDocsReducer,
   attendanceLogsReducer,
    employeesByDepartmentReducer,
     employeesByWorkLocationReducer,
      employeesByReligionReducer,  
       employeesByGenderReducer,
        employeesByBloodGroupReducer,
         employeesAverageTenureReducer,
         employeeslistWhoisOnLeaveToday  
        } from './GetBirthday';



        //version 2.3
import {recruitmentDashboardReducer} from './Recruitment/GetRecruitment';
import { performanceDashboardReducer } from './Performence/GetPerformance';
import { authorizedDevicesReducer } from './AuthDevice/GetAuthorizedDevices';
import { documentDashboardReducer } from './Documents/GetDocument';
import { assetsDashboardReducer } from './Assets/GetAssets';
import { loanDashboardReducer } from './Loan/GetLoan';
import { checkinTrendsReducer } from './GetCheckinTrends';  


 

const appReducer = combineReducers({   
  selectedItems: selectedItemsReducer,    
  allowances: allowancesReducer,
  deduction: deductioReducer,
  contribution: contributionReducer,
  taxable: taxableReducer, 
  nontaxable: nontaxableReducer,
  dependents: dependentsReducer,
  docs: addDocsReducer,
  image: addImageReducer,  
  file: addFileReducer,
  org: organizationReducer,
  employee: AddEmployee,  
  department: departmentReducer,
  designation: designationReducer,
  leave: GetLeave,
  attendance: GetAttendance,
  payroll: GetPayroll,
  transaction: GetTransaction,
  empProfile: GetEmpProfile,
  birthday: GetBirthday,
  managers: GetManagers,
  profile: profileReducer,
  auth: authReducer,
  driver: AddDriver,

  //version 2.2
  organizationDocs: organizationDocsReducer,
  attendanceLogs: attendanceLogsReducer,
  employeesByDepartment: employeesByDepartmentReducer,
  employeesByWorkLocation: employeesByWorkLocationReducer,
  employeesByReligion: employeesByReligionReducer,
  employeesByGender: employeesByGenderReducer,
  employeesByBloodGroup: employeesByBloodGroupReducer,
  employeesAverageTenure: employeesAverageTenureReducer,
  WhoisOnLeaveToday: employeeslistWhoisOnLeaveToday,

  //version 2.3
  recruitmentStats: recruitmentDashboardReducer,
  performanceStats: performanceDashboardReducer,
  authorizedDevices: authorizedDevicesReducer,
  documentStats: documentDashboardReducer,
  assetsStats: assetsDashboardReducer,
  loanStats: loanDashboardReducer,
  checkinTrends: checkinTrendsReducer,
  shifts: GetShifts,


  // Add other reducers if needed
}); 
 
const rootReducer = (state, action) => {
  if (action.type === 'CLEAR_PERSISTED_DATA') {
    storage.removeItem('persist:ro')
    storage.removeItem('persist:token')
    const { em, at, le, or, ...rest } = state;

    // Return the new state
    return rest;
    // return {
    //   ...state,
    //   employee: {
    //     employees: [],
    //   },
    //   attendance: {
    //     attendance: [],
    //   },
    //   leave: {
    //     leave: [],
    //   },

    //   // Add other parts of your state here
    // };
  }
  return appReducer(state, action)
}

export default rootReducer;
