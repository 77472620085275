// actions.js
export const CLEAR_PERSISTED_DATA = 'CLEAR_PERSISTED_DATA';
export const ADD_SELECTED_ITEM = 'ADD_SELECTED_ITEM';
export const ADD_ALLOWANCE = 'ADD_ALLOWANCE';
export const ADD_DEDUCTION = 'ADD_DEDUCTION';
export const ADD_CONTRIBUTION = 'ADD_CONTRIBUTION';
export const REMOVE_ALLOWANCE = 'REMOVE_ALLOWANCE';
export const REMOVE_CONTRIBUTION = 'REMOVE_CONTRIBUTION';
export const REMOVE_DEDUCTION = 'REMOVE_DEDUCTION';
export const TAXABLE = 'TAXABLE';
export const NON_TAXABLE = 'NON_TAXABLE';
export const DEPENDENTS = 'DEPENDENTS';
export const REMOVE_DEPENDENTS = 'REMOVE_DEPENDENTS';
export const ADD_DOCS = 'ADD_DOCS';
export const REMOVE_DOCS = 'REMOVE_DOCS';
export const IMAGE_DOCS = 'IMAGE_DOCS';
export const FILE_DOCS = 'FILE_DOCS';
export const ADD_ORG = 'ADD_ORG';
export const ADD_EMP = 'ADD_EMP';
export const SET_DEPARTMENTS = 'SET_DEPARTMENTS';
export const ADD_DEPARTMENT = 'ADD_DEPARTMENT';
export const UPDATE_DEPARTMENT = 'UPDATE_DEPARTMENT';
export const REMOVE_DEPARTMENT = 'REMOVE_DEPARTMENT';
export const SET_DESIGNATIONS = 'SET_DESIGNATIONS';
export const ADD_DESIGNATION = 'ADD_DESIGNATION';
export const UPDATE_DESIGNATION = 'UPDATE_DESIGNATION';
export const REMOVE_DESIGNATION = 'REMOVE_DESIGNATION';
export const ADD_ATTEND = 'ADD_ATTEND';
export const ADD_LEAVE = 'ADD_LEAVE';
export const ADD_PAY = 'ADD_PAY';
export const ADD_TRANSACTION = 'ADD_TRANSACTION';
export const ADD_EMP_PROFILE = 'ADD_EMP_PROFILE';
export const ADD_BIRTHDAY = 'ADD_BIRTHDAY';
export const SET_MANAGERS = 'SET_MANAGERS';
export const ADD_MANAGER = 'ADD_MANAGER';
export const UPDATE_MANAGER = 'UPDATE_MANAGER';
export const DELETE_MANAGER = 'DELETE_MANAGER';

export const MANAGER_PROFILE = 'MANAGER_PROFILE';

export const REMOVE_FILE = 'REMOVE_FILE';

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';

export const LOGOUT ="LOGOUT"

export const ADD_DRIVER ="ADD_DRIVER"

// Reset Form
export const RESET_ALLOWANCES = 'RESET_ALLOWANCES';
export const RESET_DEDUCTIONS = 'RESET_DEDUCTIONS';
export const RESET_CONTRIBUTIONS = 'RESET_CONTRIBUTIONS';
export const RESET_DOCS = 'RESET_DOCS';
export const RESET_DEPENDENTS = 'RESET_DEPENDENTS';
export const REMOVE_IMAGE = 'REMOVE_IMAGE';



//version 2.2
export const GET_ORGANIZATION_DOCS = 'GET_ORGANIZATION_DOCS';
export const GET_ATTENDANCE_LOGS = 'GET_ATTENDANCE_LOGS';
export const GET_EMPLOYEES_BY_DEPARTMENT = 'GET_EMPLOYEES_BY_DEPARTMENT';
export const GET_EMPLOYEES_BY_WORK_LOCATION = 'GET_EMPLOYEES_BY_WORK_LOCATION';
export const GET_EMPLOYEES_BY_RELIGION = 'GET_EMPLOYEES_BY_RELIGION';
export const GET_EMPLOYEES_BY_GENDER = 'GET_EMPLOYEES_BY_GENDER';
export const GET_EMPLOYEES_BY_BLOOD_GROUP = 'GET_EMPLOYEES_BY_BLOOD_GROUP';
export const GET_EMPLOYEES_AVERAGE_TENURE = 'GET_EMPLOYEES_AVERAGE_TENURE';
export const GET_EMPLOYEES_LIST_WHO_IS_ON_LEAVE_TODAY = 'GET_EMPLOYEES_LIST_WHO_IS_ON_LEAVE_TODAY';



//version 2.3
export const GET_RECRUITMENT_DASHBOARD_DATA = 'GET_RECRUITMENT_DASHBOARD_DATA';
export const GET_PERFORMANCE_DASHBOARD_DATA = 'GET_PERFORMANCE_DASHBOARD_DATA';
export const GET_AUTHORIZED_DEVICES = 'GET_AUTHORIZED_DEVICES';
export const GET_DOCUMENT_DASHBOARD_DATA = 'GET_DOCUMENT_DASHBOARD_DATA';
export const GET_ASSETS_DASHBOARD_DATA = 'GET_ASSETS_DASHBOARD_DATA';
export const GET_LOAN_DASHBOARD_DATA = 'GET_LOAN_DASHBOARD_DATA';
export const GET_CHECKIN_TRENDS = 'GET_CHECKIN_TRENDS';

export const SET_SHIFTS = 'SET_SHIFTS';
export const ADD_SHIFTS = 'ADD_SHIFTS';
export const UPDATE_SHIFTS = 'UPDATE_SHIFTS';
export const DELETE_SHIFTS = 'DELETE_SHIFTS';




export const clearPersistedData = () => {
  return {
    type: CLEAR_PERSISTED_DATA,
  };
};

export const addSelectedItem = (item) => {
  return {
    type: ADD_SELECTED_ITEM,    
    payload: item,
  };
};

export const addAllowance = (allowance) => {
  return {
    type: ADD_ALLOWANCE,
    payload: allowance,
  };
};

export const removeAllowance = (index) => ({
  type: REMOVE_ALLOWANCE,
  payload: index,
});

export const addDeduction = (deduction) => {

  return {
    type: ADD_DEDUCTION,
    payload: deduction,
  };
};

export const removeDeduction = (index) => ({
  type: REMOVE_DEDUCTION,
  payload: index,
});

export const addContribution = (contribution) => {

  return {
    type: ADD_CONTRIBUTION,
    payload: contribution,
  };
};

export const removeContribution = (index) => ({
  type: REMOVE_CONTRIBUTION,
  payload: index,
});

export const addtaxable = (taxable) => {
  return {
    type: TAXABLE,
    payload: taxable,
  };
};

export const addnontaxable = (taxable) => {

  return {
    type: NON_TAXABLE,
    payload: taxable,
  };
};

export const adddependedents = (dependents) => {

  return {
    type: DEPENDENTS,
    payload: dependents,
  };
};

export const removeDependent = (index) => {

  return {
    type: REMOVE_DEPENDENTS,
    payload: index,
  };
};

export const addDocuments = (addDocs) => {

  return {
    type: ADD_DOCS,
    payload: addDocs,
  };
};

export const removeDoc = (index) => {

  return {
    type: REMOVE_DOCS,
    payload: index,
  };
};

export const addImage = (addImage) => {

  return {
    type: IMAGE_DOCS,
    payload: addImage,
  };
};

export const removeImage = () => ({
  type: 'REMOVE_IMAGE'
});


export const addFile = (addFile) => {

  return {
    type: FILE_DOCS,
    payload: addFile,
  };
};

export const addOrganization = (addOrg) => {

  return {
    type: ADD_ORG,
    payload: addOrg,
  };
};

export const setDepartments = (departments) => ({
  type: SET_DEPARTMENTS,
  payload: departments,
});

export const addDepartments = (department) => ({
  type: ADD_DEPARTMENT,
  payload: department,
});

export const updateDepartments = (department) => ({
  type: UPDATE_DEPARTMENT,
  payload: department,
});

export const removeDepartments = (departmentId) => ({
  type: REMOVE_DEPARTMENT,
  payload: departmentId,
});

export const setDesignations = (designations) => ({
  type: SET_DESIGNATIONS,
  payload: designations,
});

export const addDesignation = (designation) => ({
  type: ADD_DESIGNATION,
  payload: designation,
});

export const updateDesignation = (designation) => ({
  type: UPDATE_DESIGNATION,
  payload: designation,
});

export const removeDesignation = (designationId) => ({
  type: REMOVE_DESIGNATION,
  payload: designationId,
});

export const addEmployee = (addemp) => {

  return {
    type: ADD_EMP,
    payload: addemp,
  };
};


export const profileManager = (addManager) => {

  return {
    type: MANAGER_PROFILE,
    payload: addManager,
  };
};

export const addattendance = (addattend) => {

  return {
    type: ADD_ATTEND,
    payload: addattend,
  };
};

export const addLeave = (addleave) => {

  return {
    type: ADD_LEAVE,
    payload: addleave,
  };
};

export const addPayroll = (addpay) => {

  return {
    type: ADD_PAY,
    payload: addpay,
  };
};

export const addTransaction = (addTransHistory) => {

  return {
    type: ADD_TRANSACTION,
    payload: addTransHistory,
  };
};

export const addEmpProfile = (addEmployeeProfile) => {

  return {
    type: ADD_EMP_PROFILE,
    payload: addEmployeeProfile,
  };
};

export const addBirthday = (addBirthday) => {

  return {
    type: ADD_BIRTHDAY,
    payload: addBirthday,
  };
};


export const setManagers = (managers) => ({
  type: SET_MANAGERS,
  payload: managers,
});

export const addManager = (manager) => ({
  type: ADD_MANAGER,
  payload: manager,
});

export const updateManager = (manager) => ({
  type: UPDATE_MANAGER,
  payload: manager,
});

export const deleteManager = (managerId) => ({
  type: DELETE_MANAGER,
  payload: managerId,
});

// reset form

export const resetAllowances = () => ({
  type: RESET_ALLOWANCES,
});

export const resetDeductions = () => ({
  type: RESET_DEDUCTIONS,
});

export const resetContributions = () => ({
  type: RESET_CONTRIBUTIONS,
});

export const resetDocs = () => ({
  type: RESET_DOCS,
});


export const resetDependents = () => ({
  type: RESET_DEPENDENTS,
});


export const removeFile = () => ({
  type: REMOVE_FILE,
});

export const logoutData = () => ({
  type: LOGOUT,
});

export const loginSuccess = (userData) => ({
  type: LOGIN_SUCCESS,
  payload: userData
});

export const addDriver = (driver) => {
  return {
    type: ADD_DRIVER,
    payload: driver,
  };
};
  

//version 2.2

export const getOrganizationDocs = (payload) => {
  return {
      type: GET_ORGANIZATION_DOCS,
      payload,
  };
};

export const getAttendanceLogs = (payload) => {
  return {
      type: GET_ATTENDANCE_LOGS,
      payload,
  };
};

export const getEmployeesByDepartment = (payload) => {
  return {
      type: GET_EMPLOYEES_BY_DEPARTMENT,
      payload,
  };
};

export const getEmployeesByWorkLocation = (payload) => {
  return {
      type: GET_EMPLOYEES_BY_WORK_LOCATION,
      payload,
  };
};

export const getEmployeesByReligion = (payload) => {
  return {
      type: GET_EMPLOYEES_BY_RELIGION,
      payload,
  };
};   

export const getEmployeesByGender = (payload) => {
  return {
      type: GET_EMPLOYEES_BY_GENDER,
      payload,
  };
};

export const getEmployeesByBloodGroup = (payload) => {
  return {
      type: GET_EMPLOYEES_BY_BLOOD_GROUP,
      payload,
  };
};

export const getEmployeesAverageTenure = (payload) => {
  return {
      type: GET_EMPLOYEES_AVERAGE_TENURE,   
      payload,
  };
};

export const getEmployeesListWhoIsOnLeaveToday = (payload) => {
  return {
      type: GET_EMPLOYEES_LIST_WHO_IS_ON_LEAVE_TODAY,
      payload,
  };
};

//version 2.3

export const addRecruitmentDashboardData = (data) => {
  return {
    type: GET_RECRUITMENT_DASHBOARD_DATA,
    payload: data,
  };
};


export const addPerformanceDashboardData = (data) => {
  return {
    type: GET_PERFORMANCE_DASHBOARD_DATA,
    payload: data,
  };
};

export const addAuthorizedDevices = (data) => {
  return {
    type: GET_AUTHORIZED_DEVICES,
    payload: data,
  };
};

export const addDocumentDashboardData = (data) => {
  return {
    type: GET_DOCUMENT_DASHBOARD_DATA,
    payload: data,
  };
};

export const addAssetsDashboardData = (data) => {
  return {
    type: GET_ASSETS_DASHBOARD_DATA,
    payload: data,
  };
};

export const addLoanDashboardData = (data) => {
  return {
    type: GET_LOAN_DASHBOARD_DATA,
    payload: data,
  };
};

export const addCheckinTrends = (data) => {
  return {
    type: GET_CHECKIN_TRENDS,
    payload: data,
  };
};
export const setShifts = (shifts) => ({
  type: SET_SHIFTS,
  payload: { data: shifts }
});

export const addShift = (shift) => ({
  type: ADD_SHIFTS,
  payload: shift
});

export const updateShift = (shift) => ({
  type: UPDATE_SHIFTS,
  payload: shift
});

export const deleteShift = (shiftId) => ({
  type: DELETE_SHIFTS,
  payload: shiftId
});

