import { ADD_BIRTHDAY, 
    GET_ORGANIZATION_DOCS, 
    GET_ATTENDANCE_LOGS, 
    GET_EMPLOYEES_BY_DEPARTMENT, 
    GET_EMPLOYEES_BY_WORK_LOCATION,
    GET_EMPLOYEES_BY_RELIGION,
    GET_EMPLOYEES_BY_GENDER,
    GET_EMPLOYEES_BY_BLOOD_GROUP,
    GET_EMPLOYEES_AVERAGE_TENURE, 
    GET_EMPLOYEES_LIST_WHO_IS_ON_LEAVE_TODAY,
    LOGOUT } from "./action";

const initialState = {};

const GetBirthday = (state = initialState, action) => {
    switch (action.type) {
        case ADD_BIRTHDAY:
            return {
                ...state,
                ...action.payload,
            }; 
        case LOGOUT:
            return initialState;

        default:
            return state;
    }
};



//version 2.2
const organizationDocsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ORGANIZATION_DOCS:
            return {
                ...state,
                ...action.payload,
            };
        case LOGOUT:
            return initialState;
        default:
            return state;
    }
};
const attendanceLogsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ATTENDANCE_LOGS:
            return {
                ...state,
                ...action.payload,
            };
        case LOGOUT:
            return initialState;
        default:
            return state;
    }
};

const employeesByDepartmentReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_EMPLOYEES_BY_DEPARTMENT:
            return {
                ...state,
                ...action.payload,
            };
        case LOGOUT:
            return initialState;
        default:
            return state;
    }
};

const employeesByWorkLocationReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_EMPLOYEES_BY_WORK_LOCATION:
            return {
                ...state,
                ...action.payload,
            };
        case LOGOUT:
            return initialState;
        default:
            return state;
    }
};

const employeesByReligionReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_EMPLOYEES_BY_RELIGION:
            return {
                ...state,
                ...action.payload,
            };
        case LOGOUT:
            return initialState;
        default:
            return state;
    }
};

const employeesByGenderReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_EMPLOYEES_BY_GENDER:
            return {
                ...state,
                ...action.payload,
            };
        case LOGOUT:
            return initialState;
        default:
            return state;
    }
};

const employeesByBloodGroupReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_EMPLOYEES_BY_BLOOD_GROUP:
            return {
                ...state,
                ...action.payload,
            };
        case LOGOUT:
            return initialState;
        default:
            return state;
    }
};

const employeesAverageTenureReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_EMPLOYEES_AVERAGE_TENURE:
            return {
                ...state,
                ...action.payload,
            };
        case LOGOUT:
            return initialState;
        default:
            return state;
    }
};
const employeeslistWhoisOnLeaveToday = (state = initialState, action) => {
    switch (action.type) {
        case GET_EMPLOYEES_LIST_WHO_IS_ON_LEAVE_TODAY:
            return {
                ...state,
                ...action.payload,
            };
        case LOGOUT:
            return initialState;
        default:
            return state;
    }
};

export {
    organizationDocsReducer,
    attendanceLogsReducer,
    employeesByDepartmentReducer,
    employeesByWorkLocationReducer,
    employeesByReligionReducer,
    employeesByGenderReducer,
    employeesByBloodGroupReducer,
    employeesAverageTenureReducer,
    employeeslistWhoisOnLeaveToday,
    GetBirthday
};
  